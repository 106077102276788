const config = {};
const axios = require('axios');
axios.defaults.withCredentials = true;

let checkIfInitialized = () => {
    if (!config || !config.baseURL) {
        throw Error('SMPDataProvider was not initialized with a baseURL string.');
    }
    return true;
};

module.exports = {
    init: context => {
        if (context.baseURL) {
            config.baseURL = context.baseURL;
            console.log(`config.baseURL: ${config.baseURL}`);
        } else {
            console.log('could not find baseURL in context object');
        }

        return config.baseURL && true;
    },

    getAll: async () => {
        checkIfInitialized();

        return axios.get(`${config.baseURL}/admin/audit`).then(response => {
            return response.data.messages.map((message, index) => {
                message._id = index;
                return message;
            });
        }).catch(error => {
            return {error};
        });
    },

    getByCriteria: async (airingId=undefined, type=undefined, foreignId=undefined, start=undefined, end=undefined, status=undefined, zeusJobId=undefined) => {
        return axios.post(`${config.baseURL}/admin/audit`, {
            ...airingId && {airingId},
            ...type && type !== "all" && {type},
            ...foreignId && {foreignId},
            ...start && {start: Math.floor(start / 1000)},
            ...end && {end: Math.floor(end / 1000)},
            ...status && status !== "all" && {status},
            ...zeusJobId && {zeusJobId}
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    get: async _id => {
        checkIfInitialized();

        return axios.get(`${config.baseURL}/get?_id=${_id}`).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    getMetadata: async () => {
        return axios.post(`${config.baseURL}/admin/metadata`, {
            action: "GET"
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    setMetadata: async value => {
        return axios.post(`${config.baseURL}/admin/metadata`, {
            action: "SET",
            value
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    deleteMetadata: async value => {
        return axios.post(`${config.baseURL}/admin/metadata`, {
            action: "DELETE",
            value
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    }
};

