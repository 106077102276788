const axios = require("axios");
axios.defaults.withCredentials = false;
const config = {};

module.exports = {
    TYPE: {
        LIVE: "live"
    },

    SOURCE: {
        ODT: "odt",
        SQS: "sqs",
        ODTSQS: "odt_sqs"
    },

    init: context => {
        if (context.baseURL) {
            config.baseURL = context.baseURL;
            console.log(`config.baseURL: ${config.baseURL}`);
        } else {
            console.log('could not find baseURL in context object');
        }

        return config.baseURL && true;
    },

    get: async (type=null, source=null) => {
        let url = `${config.baseURL}/api/activity`;

        let params = {};

        if (type !== null) {
            params.type = type;
        }

        if (source !== null) {
            params.source = source;
        }
        return await axios.post(url, {}, {
            params,
            withCredentials: true
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    getByCriteria: async (criteriaMap, type=null, source=null) => {
        let url = `${config.baseURL}/api/activity`;

        if (type !== null) {
            criteriaMap.type = type;
        } else if (source !== null) {
            criteriaMap.source = source;
        }

        return await axios.post(url, {}, {
            params: criteriaMap,
            withCredentials: true
        }).then(response => {
            if (type) {
                for (let show of response.data) {
                    const encodedLivePayload = show.activity.apiPayload;
                    try {
                        const decodedLivePayload = atob(encodedLivePayload);
                        show.activity.apiPayload = JSON.parse(decodedLivePayload);
                    } catch (e) {
                        console.error("RelaysDataProvider.getyCriteria error decoding base64 payload ", encodedLivePayload);
                    }
                }
            }
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    getJobById: async (activityId, live=false, source=null) => {
        let url = `${config.baseURL}/api/activity`;

        let params = {
            id: activityId
        };

        if (live === true) {
            params.type = "live";
        } else if (source !== null) {
            params.source = source;
        }

        return await axios.post(url, {}, {
            withCredentials: true,
            params
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    getJobs: async () => {
        let url = `${config.baseURL}/api/jobs`;

        return await axios.post(url, {}, {
            withCredentials: true
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    saveJob: async job => {
        return await axios.post(`${config.baseURL}/admin/add_jobs`, {
            jobs: [job]
        })
    },

    reprocess: async (jobId, airingId) => {
        let url = `${config.baseURL}/admin/reprocess/<job_id>/<airing_id>`
            .replace("<job_id>", jobId)
            .replace("<airing_id>", airingId);

        return await axios.post(url, {}, {
            withCredentials: true
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    wakeUpDestinationZombies: async () => {
        let url = `${config.baseURL}/api/wake_up_zombies`;

        return await axios.post(url, {}, {withCredentials: true}).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    wakeUpOdtZombies: async () => {
        let url = `${config.baseURL}/api/wake_up_odt_zombies`;

        return await axios.post(url, {} , {withCredentials: true}).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    getMetadata: async () => {
        return await axios.post(`${config.baseURL}/admin/metadata`, {
            action: "get"
        }, {withCredentials: true}).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    setMetadata: async (key, value) => {
        return await axios.post(`${config.baseURL}/admin/metadata`, {
            action: "set",
            key,
            value
        }, {withCredentials: true}).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    getTemplates: async () => {
        return await axios.post(`${config.baseURL}/admin/templates`, {
            action: "get"
        }, {withCredentials: true}).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    setTemplate: async (source, type, fields) => {
        return await axios.post(`${config.baseURL}/admin/templates`, {
            action: "set",
            source,
            type,
            fields
        }, {withCredentials: true}).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    }
};
