const config = {};

module.exports = {
    init: context => {
        if (context.baseURL) {
            config.baseURL = context.baseURL;
            console.log(`config.baseURL: ${config.baseURL}`);
        } else {
            console.log('could not find baseURL in context object');
        }

        return config.baseURL && true;
    },

    getRoutes: async () => {
        let url = `${config.baseURL}/admin/routes`

        return fetch(url, {
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(payload => {
            return payload;
        })
    },

    addRoute: async (userId, newRouteData) => {
        let url = `${config.baseURL}/admin/addRoute`

        return fetch(url, {
            method: 'POST',
            body: JSON.stringify({
                routeData: newRouteData,
                id: userId
            }),
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(payload => {
            return payload;
        })
    },

    editRoute: async (userId, routeId, newRouteData) => {
        let url = `${config.baseURL}/admin/editRoute/${routeId}`

        return fetch(url, {
            method: 'POST',
            body: JSON.stringify({
                routeData: newRouteData,
                id: userId
            }),
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(payload => {
            return payload;
        })
    },

    removeRoute: async (userId, routeId) => {
        let url = `${config.baseURL}/admin/deleteRoute/${routeId}`

        return fetch(url, {
            method: 'POST',
            body: JSON.stringify({id: userId}),
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(payload => {
            return payload;
        })
    },
}
