const config = {};

module.exports = {
    init: context => {
        if (context.baseURL) {
            config.baseURL = context.baseURL;
            console.log(`config.baseURL: ${config.baseURL}`);
        } else {
            console.log('could not find baseURL in context object');
        }
        if (context.synapseCoreUrl) {
            config.synapseCoreUrl = context.synapseCoreUrl;
            console.log(`config.synapseCoreUrl: ${config.synapseCoreUrl}`);
        } else {
            console.log('could not find synapseCoreUrl in context object');
        }


        return config.baseURL && config.synapseCoreUrl && true;
    },

    get: async () => {
        let url = `${config.baseURL}/admin/getMetadata`

        return fetch(url, {
            credentials: "include"
        })
        .then(payload => {
            return payload
        })
        .catch(error => {
            return {error}
        })
    },

    set: async (key, oldValue, value) => {
        let url = `${config.baseURL}/admin/setMetadata`

        return fetch(url, {
            method: "POST",
            credentials: "include",
            body: JSON.stringify({
                key: key,
                oldValue: oldValue,
                value: value
            })
        })
        .then(payload => {
            return payload
        })
        .catch(error => {
            return {error}
        })
    },

    enableSSAI: async () => {
        let url = `${config.baseURL}/admin/setMetadata`

        let requestBody = {key: "ssaiEnabled", oldValue: {"enabled": false}, value: {enabled: true}};
        return fetch(url, {
            method: "POST",
            credentials: "include",
            body: JSON.stringify(requestBody)
        })
            .then(payload => {
                return payload
            })
            .catch(error => {
                return {error}
            })
    },

    disableSSAI: async () => {
        let url = `${config.baseURL}/admin/setMetadata`

        let requestBody = {key: "ssaiEnabled", oldValue: {"enabled": true}, value: {enabled: false}};
        return fetch(url, {
            method: "POST",
            credentials: "include",
            body: JSON.stringify(requestBody)
        })
            .then(payload => {
                return payload
            })
            .catch(error => {
                return {error}
            })
    },

    invalidateCache: async (cacheKeys) => {
        let url = `${config.synapseCoreUrl}/admin/akamai/invalidate`

        return fetch(url, {
            method: "POST",
            credentials: "include",
            body: JSON.stringify({
                "cacheTags": cacheKeys
            })
        })
        .then(payload => {
            return payload
        })
        .catch(error => {
            return {error}
        })
    },


    deleteCache: async (cacheKeys) => {
        let url = `${config.synapseCoreUrl}/admin/akamai/delete`

        return fetch(url, {
            method: "POST",
            credentials: "include",
            body: JSON.stringify({
                "cacheTags": cacheKeys
            })
        })
        .then(payload => {
            return payload
        })
        .catch(error => {
            return {error}
        })
    },


    registerMediaJson: async (mediaJsonText) => {
        let url = `${config.baseURL}/admin/register`

        return fetch(url, {
            method: "POST",
            credentials: "include",
            body: mediaJsonText
        })
        .then(response => {
            return response.status && (response.status >= 200) && (response.status < 300)
        })
        .catch(error => {
            console.log(error)
            return false
        })
    }
};
