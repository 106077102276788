const config = {};
const axios = require('axios');
axios.defaults.withCredentials = true;

let checkIfInitialized = () => {
    if (!config || !config.baseURL) {
        throw Error('OriginDataProvider was not initialized with a baseURL string.');
    }
    return true;
};

module.exports = {
    init: context => {
        if (context.baseURL) {
            config.baseURL = context.baseURL;
            console.log(`config.baseURL: ${config.baseURL}`);
        } else {
            console.log('could not find baseURL in context object');
        }

        return config.baseURL && true;
    },

    getAll: async user => {
        checkIfInitialized();

        if (!user || !user.sub)
            return [];
        return axios.post(`${config.baseURL}/all`, {
            user_id: user.sub,
            includeDisabled: true
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    get: async (mapping_id, user) => {
        checkIfInitialized();

        if (!user || !user.sub)
            return {};
        return axios.post(`${config.baseURL}/get`, {
            user_id: user.sub,
            mapping_id
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    getByBrandAndOrigin: async (brand, origins, user) => {
        checkIfInitialized();

        return await axios.post(`${config.baseURL}/get`, {
            user_id: user.sub,
            brand,
            origins
        }).then(response => {
            console.log("(OriginCDNMapperDataProvider.getByBrandAndOrigin) response: ", response.data);
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    update: async (mapping, user) => {
        checkIfInitialized();

        return axios.post(`${config.baseURL}/update`, {
            user_id: user.sub,
            mapping
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    new: async (mapping, user) => {
        checkIfInitialized();

        return axios.post(`${config.baseURL}/new`, {
            user_id: user.sub,
            mapping
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    }
};
