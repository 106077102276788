const axios = require("axios");
delete axios.defaults.headers.common["Access-Control-Allow-Origin"];


const config = {};

module.exports = {
    init: async context => {
        if (context.baseURL) {
            config.baseURL = context.baseURL;
        }

        return config.baseURL && true;
    },

    getRecentSessions: async () => {
        return await axios.post(`${config.baseURL}/getSessions`,
            null,
            {
                params: {
                    limit: 100,
                    start_time: Math.floor(new Date() / 1000) - 864000,
                    end_time: Math.floor(new Date() / 1000) + 864000
                },
                withCredentials: true
            }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    getSession: async sessionId => {
        return await axios.post(`${config.baseURL}/getSession/${sessionId}`, null, {
            withCredentials: true
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error}
        });
    }
};
