const axios = require("axios");

let config = {};

module.exports = {
    init: context => {
        config.baseURL = context.baseURL;

        return context.baseURL && true;
    },

    getMediaJSON: async mediaId => {
        return axios.get(`${config.baseURL}/v2/media/${mediaId}`, {
            params: {
                appId: process.env.SYNAPSE_APP_ID
            }
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    getOrigins: (mediaId, packagingType="hls-ts") => {
        return axios.post(`${config.baseURL}/admin/getOrigins`, {
            mediaId,
            packagingType
        }).then(response => {
            console.log("(MediumDataProvider.getOrigins: response: ", response.data);
            return response.data;
        }).catch(error => {
            console.error("(MediumDataProvider.getOrigins) error: ", error);
            return error;
        })

    }
};
