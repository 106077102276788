import axios from "axios";
axios.defaults.withCredentials = true;
const config = {};

export function init(baseUrl) {
    config.baseUrl = baseUrl;
    return config.baseUrl && true;
}

export async function scaleCluster(clusterConfig) {
    const scaleratorUrl = new URL(`${config.baseUrl}/scale`)
    console.log("Scaled!");
    console.log(clusterConfig);
    return await axios.post(scaleratorUrl, {
        method: "POST",
        configId: clusterConfig.id
    }).then(response => {
        return response;
    }).catch(error => {
        return error;
    });
}

export async function getClusterConfigs() {
    console.log(`Base Url: ${config.baseUrl}`)
    const scaleratorUrl = new URL(`${config.baseUrl}/cluster_configs`);

    // return new Promise((resolve, reject)=>{resolve(testData)});
    return await axios.get(scaleratorUrl).then(response => {
        console.log("here")
        console.log(response)
        return response;
    }).catch(error => {
        return error;
    });
}