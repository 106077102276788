const axios = require("axios");

const config = {};

module.exports = {
    init: baseURL => {
        config.baseURL = baseURL;
        return config.baseURL && true;
    },

    getProductsForBrand: async brand => {
        return await axios.get(`${config.baseURL}/api/1/products`, {
            params: {
                brand
            }
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    }
};
