var jwt = require('jsonwebtoken');
const config = {};

module.exports = {
    init: context => {
        if (context.baseURL) {
            config.baseURL = context.baseURL;
            console.log(`config.baseURL: ${config.baseURL}`);
        } else {
            console.log('could not find baseURL in context object');
        }

        return config.baseURL && true;
    },

    getData: async (payload) => {
        let url = `${config.baseURL}/admin/audit`

        return fetch(url, {
            method: "POST",
            body: JSON.stringify(payload),
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(payload => {
                return payload
            })
            .catch(error => {
                return {error}
            })
    }
};