const config = {};
const axios = require('axios');
axios.defaults.withCredentials = true;

let checkIfInitialized = () => {
    if (!config || !config.baseURL) {
        throw Error('SMPDataProvider was not initialized with a baseURL string.');
    }
    return true;
};

module.exports = {
    init: baseURL => {
        config.baseURL = baseURL;

        return config.baseURL && true;
    },

    getAll: (showDeleted=false, showDeactivated=true) => {
        checkIfInitialized();

        let queryParams = {
            showDeactivated: true
        };

        if (showDeleted) {
            queryParams.showDeleted = showDeleted;
        }

        return axios.post(`${config.baseURL}/admin/jwks.json`, "",{
            params: queryParams
        }).then(response => {
            return response.data;
        }).catch(error => {
            console.error("(KnoxDataProvider.getAll)", error);
            throw error;
        });
    },

    edit: (id, updatedInfo) => {
        checkIfInitialized();

        return axios.post(`${config.baseURL}/knox/save/${id}`, updatedInfo).then(response => {
            return response.data;
        }).catch(error => {
            console.error("(KnoxDataProvider.edit) error: ", error);
            throw error;
        });
    },

    generate: (kid, iss, aud, keyType, hashType, team) => {
        checkIfInitialized();

        return axios.post(`${config.baseURL}/admin/generate`, {
            kid, iss, aud, keyType, hashType, team
        }).then(response => {
            console.log("(KnoxDataProvider.generate) response: ", response.data);
            return response.data;
        }).catch(error => {
            console.error("(KnoxDataProvider.generate) error: ", error);
            throw error;
        });
    },

    import: (kid, pem, alg, kty, iss, aud, team) => {
        checkIfInitialized();

        return axios.post(`${config.baseURL}/admin/import`, {
            kid,
            pem,
            alg,
            kty,
            team,
            iss,
            aud
        }).then(response => {
            console.log("(KnoxDataProvider.import) response: ", response.data);
            return response.data;
        }).catch(error => {
            console.error("(KnoxDataProvider.import) error: ", error);
            throw error;
        });
    },

    deactivate: id => {
        checkIfInitialized();

        return axios.post(`${config.baseURL}/admin/deactivate/${id}`).then(response => {
            console.log("(KnoxDataProvider.deactivate) response: ", response.data);
            return response.data;
        }).catch(error => {
            console.error("(KnoxDataProvider.deactivate) error:", error);
        });
    },

    reactivate: id => {
        checkIfInitialized();

        return axios.post(`${config.baseURL}/admin/reactivate/${id}`).then(response => {
            console.log("(KnoxDataProvider.reactivate) response: ", response.data);
            return response.data;
        }).catch(error => {
            console.error("(KnoxDataProvider.reactivate) error:", error);
        });
    },

    delete: id => {
        checkIfInitialized();

        return axios.post(`${config.baseURL}/admin/delete/${id}`).then(response => {
            console.log("(KnoxDataProvider.delete) response: ", response.data);
            return response.data;
        }).catch(error => {
            console.error("(KnoxDataProvider.delete) error: ", error);
            throw error;
        });
    }
};
