const axios = require("axios");
const config = {};

module.exports = {
    init: baseURL => {
        config.baseURL = baseURL;

        return config.baseURL && true;
    },

    getMetadata: async () => {
        return await axios.post(`${config.baseURL}/admin/metadata`, {
            action: "GET"
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    setMetadata: async (key, value) => {
        return await axios.post(`${config.baseURL}/admin/metadata`, {
            action: "SET",
            key,
            value
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    }
};
